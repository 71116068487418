import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { StrapiAbout } from '../graphql-types';
import TitleBox from '../components/title-box';
import { Row, Col, Container } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import SEO from '../components/seo';

const About: React.FC<PageProps<never, { strapiAbout: StrapiAbout }>> = ({
  pageContext: {
    strapiAbout: { title, content, seo },
  },
}) => {
  return (
    <Layout>
      <SEO title={seo?.title ?? title} description={seo?.description} />

      <main>
        <TitleBox>
          <h1>{title}</h1>
        </TitleBox>
        <Container className="pt-5">
          <Row>
            <Col>
              <ReactMarkdown source={content} />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default About;
